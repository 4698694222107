/** Internal helper imports here ... */
import { filter, find, forEach, includes, map, padStart, keys } from 'lodash';
import calendar from 'calendar-js';
import Mustache from 'mustache/mustache.min';
import moment from 'moment';
import Util from '../helpers/burc_util';
import apiModel from '../helpers/apiModel';
import DomHelper from '../helpers/DomHelper';

const CALENDAR_ID = 'stonorCalendar';
const EVENTS_LIST_ID = 'eventsList';

/**
 * Module description...
 *
 * @returns {{init: init}}
 */
export default (() => {
  /**
   * Other functions...
   */

  let DOMRoot;
  let EventsList;
  let currentDays;

  const getCurrentDay = () => parseInt(DomHelper.getData(DOMRoot, 'currentDay'), 10);
  const setCurrentDay = day => parseInt(DomHelper.setData(DOMRoot, 'currentDay', day), 10);

  const getCurrentMonth = () => parseInt(DomHelper.getData(DOMRoot, 'currentMonth'), 10);
  const setCurrentMonth = month => parseInt(DomHelper.setData(DOMRoot, 'currentMonth', month), 10);

  const getCurrentMonthIndex = () => parseInt(DomHelper.getData(DOMRoot, 'currentMonth'), 10) - 1;

  const getCurrentYear = () => parseInt(DomHelper.getData(DOMRoot, 'currentYear'), 10);
  const setCurrentYear = year => parseInt(DomHelper.setData(DOMRoot, 'currentYear', year), 10);

  const getLoadingSpinner = () => document.querySelector('.loading');

  const setLoading = (loading) => {
    if (loading) {
      DOMRoot.setAttribute('style', `min-height: ${DOMRoot.clientHeight}px`);
      getLoadingSpinner()
        .classList
        .add('loading--on');
      DOMRoot.classList.add('events__list--loading');
    } else {
      DOMRoot.setAttribute('style', `min-height: 0`);
      getLoadingSpinner()
        .classList
        .remove('loading--on');
      DOMRoot.classList.remove('events__list--loading');
    }
  };

  const getCalendarData = () => {
    const monthData = calendar()
      .detailed(getCurrentYear(), getCurrentMonthIndex(), (data, calendarObject) => {
        return Object.assign(
          {
            weekday: calendarObject.weekdays[data.index.day],
            weekdayNum: data.index.day === 0 ? 6 : data.index.day - 1
          },
          data
        );
      });

    const days = [];

    forEach(monthData.calendar, (weekData) => {
      const inMonthWeekDays = filter(weekData, day => day.isInPrimaryMonth);

      days.push(...inMonthWeekDays);
    });

    const firstDayIndex = days[0].weekdayNum;
    const lastDayIndex = days[days.length - 1].weekdayNum;

    // add blank items to start of calendar (prev month)
    for (let i = 0; i < firstDayIndex; i += 1) {
      days.unshift({
        blank: true,
        day: moment
      });
    }

    // add blank items to end of calendar (next month)
    for (let i = 7; i > lastDayIndex; i -= 1) {
      days.push({
        blank: true,
        day: ''
      });
    }

    return days;
  };

  const mapCalendarData = async (calendarData) => {
    const eventCollection = await apiModel.get('events', {
      month: getCurrentMonth(),
      year: getCurrentYear()
    });

    return map(calendarData, (day) => {
      if (day.blank) {
        return day;
      }

      const events = filter(eventCollection, (event) => {
        const mappedEvent = event;
        mappedEvent.title = Util.decodeHtml(event.title);
        mappedEvent.isRace = false;
        mappedEvent.isTraining = false;
        mappedEvent.isSocial = false;

        switch (event.event_type) {
          default:
            break;
          case 'race':
            mappedEvent.isRace = true;
            break;
          case 'training':
            mappedEvent.isTraining = true;
            break;
          case 'social':
            mappedEvent.isSocial = true;
            break;
        }

        return mappedEvent ? includes(mappedEvent.days, padStart(day.day, 2, "0")) : false;
      });

      const newDay = day;
      newDay.modifiers = '';

      newDay.events = events;
      newDay.hasEvent = !!newDay.events.length;
      newDay.bike = events.some(event => event.bike);
      newDay.swim = events.some(event => event.swim);
      newDay.run = events.some(event => event.run);
      newDay.social = events.some(event => event.isSocial);

      return newDay;
    });
  };

  const clearEventsList = () => {
    EventsList.innerHTML = '';
  };

  const resetDayText = () => {
    if (document.querySelectorAll('.animText').length > 1) {
      document.querySelector('.animText--out').remove();
      document.querySelector('.animText--in').classList.remove('animText--in', 'animText--new');
    }
  };

  const updateDayText = (date) => {
    resetDayText();

    const AnimText = document.querySelector('.animText');

    const dateString = `${date.format('D MMMM')}`;
    const newText = document.createElement('span');
    AnimText.insertAdjacentElement('afterend', newText);
    newText.classList.add('animText', 'animText--new');
    newText.innerText = dateString;

    setTimeout(() => {
      newText.classList.add('animText--in');
      AnimText.classList.add('animText--out');
    }, 1);
  };

  const changeMonth = async (newDate) => {
    clearEventsList();

    setCurrentDay(newDate.clone().format('D'));
    setCurrentMonth(newDate.format('M'));
    setCurrentYear(newDate.format('YYYY'));

    currentDays = await mapCalendarData(getCalendarData());

    document.getElementById('calendarMonth').innerHTML = newDate.format('MMM');
    document.getElementById('calendarYear').innerHTML = newDate.format('YYYY');

    DOMRoot.innerHTML = Mustache.render(document.getElementById('CalendarDayTemplate').innerHTML, {
      days: currentDays
    });

    setTimeout(() => {
      setEventListeners();
      document.querySelector(`.dayClick--day${getCurrentDay()}`).click();
    }, 1);
  };

  const loadDay = (day) => {
    const selectedDate = moment(`${getCurrentYear()}-${padStart(getCurrentMonth(), 2, "0")}-${padStart(day, 2, "0")}`);

    updateDayText(selectedDate);

    const currentDay = find(currentDays, theDay => parseInt(day, 10) === theDay.day);

    const eventList = {
      hasEvent: currentDay.hasEvent,
      events: currentDay.events,
    };

    forEach(currentDay.openingTimes, (openingTime) => {
      eventList[openingTime.type] = openingTime;
    });

    eventList.allEmpty = eventList.events.length < 1 && keys(eventList).length < 3;

    EventsList.innerHTML = Mustache.render(document.getElementById('DayEventsTemplate').innerHTML, eventList);
  };

  const setEventListeners = () => {
    forEach(document.querySelectorAll('.dayClick:not(.stonorCalendar__day--blank)'), (calendarDayNode) => {
      calendarDayNode.addEventListener('click', () => {
        if (!calendarDayNode.classList.contains('dayClick--selected')) {
          const currentlySelected = document.querySelector('.dayClick--selected');
          if (currentlySelected) {
            currentlySelected.classList.remove('dayClick--selected');
          }

          calendarDayNode.classList.add('dayClick--selected');
          loadDay(calendarDayNode.getAttribute('data-day'));
        }
      });
    });
  };

  const init = async () => {
    if (DomHelper.isPageTemplate('opening-times')) {
      DOMRoot = document.getElementById(CALENDAR_ID);
      EventsList = document.getElementById(EVENTS_LIST_ID);

      currentDays = await mapCalendarData(getCalendarData());

      DOMRoot.innerHTML = Mustache.render(document.getElementById('CalendarDayTemplate').innerHTML, {
        days: currentDays
      });

      setTimeout(() => {
        setEventListeners();
        forEach(document.querySelectorAll('.calendarMonthControl'), (monthControlNode) => {
          monthControlNode.addEventListener('click', async () => {
            const currentDate = moment(`${getCurrentYear()}-${padStart(getCurrentMonth(), 2, '0')}-${padStart(getCurrentDay(), 2, '0')}`);
            const timeFrameDirection = monthControlNode.getAttribute('data-timeframe');

            setLoading(true);

            if (timeFrameDirection === 'previous') {
              const prevDate = currentDate.clone().subtract(1, 'months').startOf('month');
              await changeMonth(prevDate);
            } else {
              const nextDate = currentDate.clone().add(1, 'months').startOf('month');
              await changeMonth(nextDate);
            }

            setLoading(false);
          });
        });
        document.querySelector(`.dayClick--day${getCurrentDay()}`).click();
      }, 1);
    }
  };

  return {
    init
  };
})();
