/** Import polyfills here * */
import "core-js/features/object/values";
import "core-js/features/array/for-each";
import "regenerator-runtime/runtime";

import App from "./modules/App";

(() => {
  App.init();
})();
