export default (() => {
  const getNodeActualHeight = (node) => {
    node.setAttribute('style', 'height: auto');
    const height = node.clientHeight;
    node.removeAttribute('style');

    return height;
  };

  const isPageTemplate = (templateName) => {
    return document.body.classList.contains(`page-template-template-${templateName}`);
  };

  const getData = (DOMRoot, dataName) => {
    return DOMRoot.attributes[`data-${dataName}`].value;
  };

  const setData = (DOMRoot, dataName, dataValue) => {
    DOMRoot.setAttribute(`data-${dataName}`, dataValue);
  };

  return {
    getNodeActualHeight,
    getData,
    setData,
    isPageTemplate
  };
})();
