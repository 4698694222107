/** Internal helper imports here ... */
import { forEach } from "lodash";
import Glide from "@glidejs/glide";

/**
 * Module description...
 *
 * @returns {{init: init}}
 */
export default (() => {
  /**
   * Other functions...
   */

  const createSlider = (slider, index, options, id = false) => {
    let glide;

    if (id) {
      glide = new Glide(`#${id + (index + 1)}`, options).mount();
    } else {
      glide = new Glide(`#glide${index + 1}`, options).mount();
    }

    slider.querySelectorAll(".glide__arrow").forEach(node => {
      node.addEventListener("click", e => {
        e.preventDefault();
        glide.go(node.attributes["data-glide-dir"].value);
      });
    });

    return glide;
  };

  const init = () => {
    const boxSlider = document.querySelectorAll(".TwoColumnSlider");

    if (boxSlider.length) {
      forEach(boxSlider, (slider, index) => {
        createSlider(
          slider,
          index,
          {
            type: "carousel",
            gap: 0,
            perView: 1,
            rewind: false,
            autoplay: 8000,
            focusAt: "center"
          },
          "boxImageGlide"
        );
      });
    }
  };

  return {
    init
  };
})();
