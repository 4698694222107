/** Internal helper imports here ... */

/**
 * Module description...
 *
 * @returns {{init: init}}
 */
export default (() => {
  /**
   * Other functions...
   */

  const init = () => {
    const checkBoxId = "hamburger-toggle";
    const navigation = document.querySelector("#navigation");
    const checkbox = document.querySelector(`input#${checkBoxId}`);
    let navigationHeight;

    if (checkbox) {
      checkbox.addEventListener("change", e => {
        if (e.target.checked) {
          navigation.setAttribute("style", "height: auto");
          navigationHeight = navigation.clientHeight;
          navigation.removeAttribute("style");
          setTimeout(() => {
            navigation.setAttribute("style", `height: ${navigationHeight}px`);
            navigation.classList.add("header__navigation--toggled");
          }, 1);
        } else {
          navigation.removeAttribute("style");
          navigation.classList.remove("header__navigation--toggled");
        }
      });
    }
  };

  return {
    init
  };
})();
