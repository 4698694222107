import {
  map, forEach, split, get as _get,
} from 'lodash';

export default (() => {
  const API_OPTIONS = {
    baseUrl: `/wp-json/tfn/v1/`,
  };

  const Model = async (data, template) => {
    const resolvedData = await data;

    if (template) {
      return map(resolvedData, (singleResource) => {
        const mappedData = {};

        forEach(template, (value, property) => {
          const pathFromValue = split(value, '.');

          mappedData[property] = _get(singleResource, pathFromValue);
        });

        return mappedData;
      });
    }

    return resolvedData;
  };

  const get = async (resource, params, template) => {
    const requestUrl = new URL(`${tfntriGlobal.root}${API_OPTIONS.baseUrl}${resource}`);
    requestUrl.search = new URLSearchParams(params).toString();

    const response = await fetch(requestUrl.toString());

    return Model(response.json(), template);
  };

  return {
    get,
  };
})();
