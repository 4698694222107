/** Internal helper imports here ... */
import { forEach } from 'lodash';
import Glide from '@glidejs/glide';

/**
 * Module description...
 *
 * @returns {{init: init}}
 */
export default (() => {
  /**
   * Other functions...
   */

  const createSlider = (slider, index, options) => {
    const glide = new Glide(`#glide${index + 1}`, options).mount();

    slider.querySelectorAll('.glide__arrow').forEach(node => {
      node.addEventListener('click', () => {
        glide.go(node.attributes['data-glide-dir'].value);
      });
    });
  };

  const init = () => {
    const glideSliders = document.querySelectorAll('.Slider');

    if (glideSliders.length) {
      forEach(glideSliders, (slider, index) => {
        createSlider(slider, index, {
          type: 'slider',
          gap: 0,
          perView: 1,
          rewind: false,
          breakpoints: {
            1310: {
              perView: 1
            }
          }
        });
      });
    }
  };

  return {
    init
  };
})();
