/** Internal helper imports here ... */
import { map, replace, forEach } from 'lodash';
import Mustache from 'mustache/mustache.min';
import ApiModel from '../helpers/apiModel';

const POSTS_ID = 'ProjectsList';

class Posts {
  constructor() {
    this.container = document.querySelector('#ProjectsList');

    if (this.container) {
      this.initialiseState();
    }
  }

  initialiseState() {
    this.state = {
      search: '',
      selectedCategory: '',
      page: 1,
      template: document.getElementById('ProjectsTemplate').innerHTML,
      emptyTemplate: document.getElementById('NoProjectsTemplate').innerHTML,
      currentPosts: null
    };
  }

  async init() {
    this.state.currentPosts = await this.getPosts();
    this.showPosts();
  }

  showPosts() {
    if (!this.state.currentPosts || !this.state.currentPosts.length) {
      this.container.innerHTML = Mustache.render(this.state.emptyTemplate);
    } else {
      this.container.innerHTML = Mustache.render(this.state.template, {
        posts: map(this.state.currentPosts, this.mapPostToTemplate.bind(this))
      });
    }
  }

  async update(newState) {
    this.state = Object.assign(this.state, newState);

    this.state.currentPosts = await this.getPosts();
    this.clearPosts();
    this.showPosts();
  }

  getPosts() {
    return ApiModel.get('projects', {
      q: this.state.search,
      page_number: this.state.page,
      category: this.state.selectedCategory
    });
  }

  clearPosts() {
    this.container.innerHTML = '';
  }

  mapPostToTemplate(post) {
    const newPost = post;

    newPost.title = replace(post.title, RegExp('(&#038;)+', 'g'), '&');
    newPost.excerpt = replace(post.excerpt, RegExp('(&#038;)+', 'g'), '&');

    return newPost;
  }
}

/**
 * Module description...
 *
 * @returns {{init: init}}
 */
export default (() => {
  let DOMRoot;
  let Post;
  let defaultFilter;

  const getLoadingSpinner = () => document.querySelector('.loading');

  const setLoading = loading => {
    if (!DOMRoot) {
      DOMRoot = document.querySelector(`#${POSTS_ID}`);
    }

    if (loading) {
      DOMRoot.setAttribute('style', `min-height: ${DOMRoot.clientHeight}px`);
      getLoadingSpinner().classList.add('loading--on');
    } else {
      DOMRoot.setAttribute('style', 'min-height: 0');
      getLoadingSpinner().classList.remove('loading--on');
    }
  };

  const changeCategory = async node => {
    setLoading(true);

    document
      .querySelector('.hero-image__categories__item--selected')
      .classList.remove('hero-image__categories__item--selected');
    node.classList.add('hero-image__categories__item--selected');

    await Post.update({
      search: '',
      selectedCategory: node.attributes['data-category'].value,
      page: 1
    });

    setLoading(false);
  };

  const init = async () => {
    if (document.body.classList.contains('post-type-archive-projects')) {
      defaultFilter = document.querySelector(
        '.hero-image__categories__item--selected'
      );

      setLoading(true);

      Post = new Posts({});

      await Post.init();

      setLoading(false);

      forEach(
        document.querySelectorAll('.hero-image__categories__item'),
        node => {
          node.addEventListener('click', () => {
            changeCategory(node);
          });
        }
      );
    }
  };

  return {
    init
  };
})();
