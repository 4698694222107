/**
 * Import additional modules here:
 *
 * import './module_xyz';
 */

import Media from "../helpers/matchMediaGenerator";
import Hamburger from "./Hamburger";
import Slider from "./Slider";
import Projects from "./Projects";
import News from "./News";
import BoxSlider from "./BoxSlider";
import BackToTop from "./BackToTop";
import Calendar from './Calendar';

/**
 * Main App module used in index.js to initialise all other modules, includes helper functions/modules for use in initialising
 * modules depending on various criteria
 *
 * @returns {{init: init}}
 */
export default (() => {
  "use strict";

  const init = () => {
    /**
     * Initialise other modules here, check for prefer reduced motion for animations, etc

      // Only initialise a module when screen width criteria is met:
      // Module name is: IntersectionLazyLoad
      initialiseInBreakpoint([200, 700], IntersectionLazyLoad); // Between 200px and 700px
      initialiseInBreakpoint([768, false], IntersectionLazyLoad); // Greater than 768px
      initialiseInBreakpoint([false, 967], IntersectionLazyLoad); // Less than 967px

      // Only initialise a module if prefers reduced motion isn't set (user hasn't requested no animation)
      // Module name is: AnimateElements
      initialiseIfAnimationAllowed(AnimateElements);
     */

    Hamburger.init();
    Slider.init();
    Projects.init();
    News.init();
    BoxSlider.init();
    BackToTop.init();
    Calendar.init();
  };

  /**
   * @param Module {Object}
   * @param Module.init {Function}
   *
   * Detects preferred reduce motion before initiating the module
   */
  const initialiseIfAnimationAllowed = Module => {
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      Module.init();
    }
  };

  /**
   * @param breakpoints {Array}
   *    [
   *      100, // MIN BREAKPOINT Or false if no min
   *      800 // MAX BREAKPOINT Or false if no max
   *    ]
   *
   * @param Module {Object}
   * @param Module.init {Function} - initialises the module
   *
   * Detects media query is reached before initiating the module, uses resize event listener to initialise the module
   * when this breakpoint is reached
   */
  const initialiseInBreakpoint = (breakpoints, Module) => {
    const media = Media.getMedia(breakpoints);
    if (!media) return false;

    if (media.matches) {
      Module.init();
    } else {
      const initOnResize = () => {
        if (media.matches) {
          Module.init();
          window.removeEventListener("resize", initOnResize);
        }
      };

      window.addEventListener("resize", initOnResize);
    }
  };

  return {
    init
  };
})();
